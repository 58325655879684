import React, {useState} from 'react';
import {Router, Switch} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/core/styles';
import {Paper, CssBaseline, useMediaQuery} from '@material-ui/core';
import AOS from 'aos';

import theme from './theme';
import Routes from './Routes';


import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'aos/dist/aos.css';
import {Footer, Sidebar, Topbar} from "./layouts/Main/components";
import AppProvider from "./AppContext";

const browserHistory = createBrowserHistory();

browserHistory.listen(location => {
  // Use setTimeout to make sure this runs after React Router's own listener
  setTimeout(() => {
    // Keep default behavior of restoring scroll position when user:
    // - clicked back button
    // - clicked on a link that programmatically calls `history.goBack()`
    // - manually changed the URL in the address bar (here we might want
    // to scroll to top, but we can't differentiate it from the others)
    if (location.action === 'POP') {
      return;
    }
    // In all other cases, scroll to top
    window.scrollTo(0, 0);
  });
});

const App = () => {
  AOS.init({
    once: true,
    delay: 50,
    duration: 500,
    easing: 'ease-in-out',
  });

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;
  return (

      <AppProvider>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <Paper>
        <Router history={browserHistory}>
          <Topbar onSidebarOpen={handleSidebarOpen}  />
          <Sidebar
              onClose={handleSidebarClose}
              open={open}
              variant="temporary"
              // pages={pages}
          />
          <Routes />

        </Router>
      </Paper>
      <Footer />
    </ThemeProvider>
      </AppProvider>
  );
}

export default App;