/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {
    List,
    ListItem,
    Typography,
    ListItemIcon,
    Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PhoneIcon from '@material-ui/icons/Phone';

const useStyles = makeStyles(theme => ({
    root: {},
    listItem: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    listItemIcon: {
        minWidth: 'auto',
    },
    listItemLink: {
        textDecoration: 'none',
    },
    closeIcon: {
        justifyContent: 'flex-end',
        cursor: 'pointer',
    },
    divider: {
        width: '100%',
    },
    listItemPhone: {
        paddingTop: 30,
        paddingBottom: 0,
        color: '#1976d2',
        textDecoration: 'none'
    }
}));

const SidebarNav = props => {
    const {pages, onClose, className, ...rest} = props;
    const classes = useStyles();

    return (
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.closeIcon} onClick={onClose}>
                <ListItemIcon className={classes.listItemIcon}>
                    <CloseIcon fontSize="small"/>
                </ListItemIcon>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Typography
                    variant="h6"
                    color="primary"
                    component="a"
                    href="/home"
                    className={classes.listItemLink}
                >
                    Strona główna
                </Typography>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Typography
                    variant="h6"
                    color="primary"
                    component="a"
                    href="/projects"
                    className={classes.listItemLink}
                >
                    Nasze realizacje
                </Typography>
            </ListItem>
            <ListItem className={classes.listItemPhone}>
                <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.root}
                >
                            <PhoneIcon style={{height: 12}}/>
                    <a className={classes.listItemPhone} href="tel:+48507149692">+48 507 149 692</a>
                </Typography>
            </ListItem>

        </List>
    );
};

SidebarNav.propTypes = {
    className: PropTypes.string,
    pages: PropTypes.object.isRequired,
    onClose: PropTypes.func,
};

export default SidebarNav;
