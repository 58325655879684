import React from 'react';

import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useMediaQuery, Grid, Typography, Link} from '@material-ui/core';
import {Image} from 'components/atoms';
import {SectionHeader} from 'components/molecules';
import {Section} from "../../../components/organisms";


const useStyles = makeStyles(theme => ({
    root: {},
    formContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
        maxWidth: 500,
        margin: `0 auto`,
    },
    section: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    image: {
        boxShadow:
            '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
        borderRadius: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            maxWidth: 500,
        },
    }
}));


const WeglokoksBis = (props) => {
    const {className, ...rest} = props;
    const classes = useStyles();

    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    return (

        <Section className={classes.pagePaddingTop}>
            <Grid
                container
                justify="space-between"
                spacing={4}
                direction={isMd ? 'row' : 'column-reverse'}
            >
                <Grid
                    item
                    container
                    alignItems="center"
                    xs={12}
                    md={6}
                    data-aos={'fade-up'}
                >
                    <SectionHeader
                        title={
                            <span>
      Sklep internetowy
                <br/>
                <Typography component="span" variant="inherit" color="primary">

                   <Link
                       style={{textDecoration: 'none'}}
                       target="_blank"
                       href='https://www.wegiel-torun.pl/'>wegiel-torun.pl</Link>
                </Typography>
              </span>
                        }
                        subtitle="Obsługuje specyficzne wymagania dotyczące podatku akcyzowego oraz wylicza transport w zależności od strefy.
                            Zaimplementowano również płatności przelewy24 i panel administratora.
                            Użyte technologie: Java, Spring, Javascript, React.js, PostgreSQL, Ubuntu, Nginx"

                        align="left"
                        disableGutter
                        titleVariant="h3"
                    />

                </Grid>
                <Grid
                    item
                    container
                    justify="flex-start"
                    alignItems="center"
                    xs={12}
                    md={6}
                    data-aos={'fade-up'}
                >
                    <Image
                        src="/images/illustrations/weglokoks_bis_shop.jpg"
                        alt="TheFront Company"
                        className={classes.image}
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000"

                    />
                </Grid>
            </Grid>

            <Grid
                container
                spacing={0}
                direction="row"
                alignItems="center"
                justify="center"

            >
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justify="center"
                    // style={{width: 1024}}
                >

                    <Image
                        style={{marginTop: 30}}
                        src="/images/illustrations/weglokoks_panel.png"
                        alt="CodeWorld Company"
                        className={classes.image}
                        data-aos="fade-up"
                    />
                </Grid>
            </Grid>

            <Grid
                container
                spacing={0}
                direction="row"
                alignItems="center"
                justify="center"

            >
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justify="center"
                    // style={{width: 1024}}
                >

                    <Image
                        style={{marginTop: 30}}
                        src="/images/illustrations/weglokoks_products.png"
                        alt="CodeWorld Company"
                        className={classes.image}
                        data-aos="fade-up"
                    />
                </Grid>
            </Grid>


        </Section>

    );
};

export default WeglokoksBis;