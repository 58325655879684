import React from 'react';
import clsx from 'clsx';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useMediaQuery, Grid, Button, Typography, Paper, Container, Divider} from '@material-ui/core';
import MaszynyPolskie from "./MaszynyPolskie";
import WeglokoksBis from "./WeglokoksBis/WeglokoksBis";
import ServiceHelpdesk from "./Service-Helpdesk/ServiceHelpdesk";


const useStyles = makeStyles(theme => ({
    root: {},
    formContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
        maxWidth: 500,
        margin: `0 auto`,
    },
    section: {
        paddingTop: 0,
        paddingBottom: 0,
    },
}));


const Projects = (props) => {
    const {className, ...rest} = props;
    const classes = useStyles();

    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    return (
        <div className={clsx(classes.root, className)} >
            <Typography style={{paddingTop: 130}}  variant="h2" gutterBottom align='center' >
               Nasze realizacje
            </Typography>
            <ServiceHelpdesk/>
            <Divider/>
            <MaszynyPolskie/>
            <Divider/>
            <WeglokoksBis/>
        </div>
    );
};

export default Projects;