import React, {createContext, useState} from 'react';

export const AppContext = createContext();

const AppProvider = ({children}) => {


    //---------------------------PRIMARY-------------------------------------
    const [home, setHome] = useState({
        senderEmail: '',
        emailMessage: '',
        errorEmail: false,
        errorEmailMessage: '',
        errorMessage: false,
        errorMessageMessage: '',
        successSendEmail: '',
    });
    const toggleHomeState = (value) => setHome(value);


    return (
        <AppContext.Provider value={{
            home, toggleHomeState,
        }}>
            {children}
        </AppContext.Provider>
    )

};

export default AppProvider;

