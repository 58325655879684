import React from 'react';

import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useMediaQuery, Grid, Button, Typography, Paper, Container, Link} from '@material-ui/core';
import {Image} from 'components/atoms';
import {SectionHeader} from 'components/molecules';
import {Section} from "../../../components/organisms";


const useStyles = makeStyles(theme => ({
    root: {},
    formContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
        maxWidth: 500,
        margin: `0 auto`,
    },
    section: {
        paddingTop: 0,
        paddingBottom: 0,
    },
}));


const ServiceHelpdesk = (props) => {
    const {className, ...rest} = props;
    const classes = useStyles();

    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    return (

        <Section className={classes.pagePaddingTop}>
            <Grid
                container
                justify="space-between"
                spacing={4}
                direction={isMd ? 'row' : 'column-reverse'}
            >
                <Grid
                    item
                    container
                    alignItems="center"
                    xs={12}
                    md={6}
                    data-aos={'fade-up'}
                >
                    <SectionHeader
                        title={
                            <span>
                Serwis www oraz aplikacja mobilna
                <br/>
                <Typography component="span" variant="inherit" color="primary">
                     <Link
                         style={{textDecoration: 'none'}}
                         target="_blank"
                         href='https://service-helpdesk.pl'>Service-Helpdesk</Link>

                </Typography>
              </span>
                        }
                        subtitle="Aplikacja zarządzająca serwisem różnych urządzeń i maszyn.
                        Aplikacja pozwoli uporządkować pracę serwisanów oraz umożliwia dostęp do wielu przydatnych funkcji.
                        Użyte technologie: Java, Spring, Javascript, React.js, Android SDK, PostgreSQL
                            "
                        align="left"
                        disableGutter
                        titleVariant="h3"
                    />

                </Grid>
                <Grid
                    item
                    container
                    justify="flex-start"
                    alignItems="center"
                    xs={12}
                    md={6}
                    data-aos={'fade-up'}
                >
                    <Image
                        src="/images/illustrations/service_helpdesk_smartphones.png"
                        alt="TheFront Company"
                        className={classes.image}
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000"
                    />
                </Grid>
            </Grid>


            <Grid
                container
                spacing={0}
                direction="row"
                alignItems="center"
                justify="center"

            >
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justify="center"
                >
                    <Paper style={{marginTop: 100}}>
                        <Image
                            src="/images/illustrations/service_helpdesk_fullpage.png"
                            alt="CodeWorld Company"
                            className={classes.image}
                            data-aos="fade-up"
                        />
                    </Paper>
                    <Paper style={{marginTop: 100}}>
                        <Image
                            src="/images/illustrations/service_helpdesk_machine_page.png"
                            className={classes.image}
                            data-aos="fade-up"
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Section>

    );
};

export default ServiceHelpdesk;