import React from 'react';

import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useMediaQuery, Grid, Button, Typography, Paper, Container, Link} from '@material-ui/core';
import {Image} from 'components/atoms';
import {SectionHeader} from 'components/molecules';
import {Section} from "../../../components/organisms";



const useStyles = makeStyles(theme => ({
    root: {},
    formContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
        maxWidth: 500,
        margin: `0 auto`,
    },
    section: {
        paddingTop: 0,
        paddingBottom: 0,
    },
}));


const MaszynyPolskie = (props) => {
    const {className, ...rest} = props;
    const classes = useStyles();

    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    return (

            <Section className={classes.pagePaddingTop}>
                <Grid
                    container
                    justify="space-between"
                    spacing={4}
                    direction={isMd ? 'row' : 'column-reverse'}
                >
                    <Grid
                        item
                        container
                        alignItems="center"
                        xs={12}
                        md={6}
                        data-aos={'fade-up'}
                    >
                        <SectionHeader
                            title={
                                <span>
                Aplikacja mobilna na platformę android + aplikacja web-owa
                <br/>
                <Typography component="span" variant="inherit" color="primary">
                     <Link
                         style={{textDecoration: 'none'}}
                         target="_blank"
                         href='https://maszyny-polskie.com.pl/'>Maszyny Polskie</Link>

                </Typography>
              </span>
                            }
                            subtitle="Aplikacja zarządzająca serwisem maszyn do metalu (wypalarek CNC, pras krawędziowych itp.)
                            Zawiera bazę danych maszyn, kontrahentów i serwisantów. Umożliwia dodawanie plików, dokumentów, parametrów
                            części maszyny i notatek. Zarządza dokumentami serwisowymi, umożliwia podpisanie dokumentów
                            serwisowych na ekranie smartfona tworzy je również w pdf. Użyte technologie: Java, Spring, Javascript, React.js, Android,
                            MySQL, Ubuntu, Nginx
                             "
                            align="left"
                            disableGutter
                            titleVariant="h3"
                        />

                    </Grid>
                    <Grid
                        item
                        container
                        justify="flex-start"
                        alignItems="center"
                        xs={12}
                        md={6}
                        data-aos={'fade-up'}
                    >
                        <Image
                            src="/images/illustrations/maszyny_polskie_mobile_preview.jpg"
                            alt="TheFront Company"
                            className={classes.image}
                            data-aos="flip-left"
                            data-aos-easing="ease-out-cubic"
                            data-aos-duration="2000"
                        />
                    </Grid>
                </Grid>



                <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justify="center"

                >
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        alignItems="center"
                        justify="center"
                        style={{ width: 550 }}
                    >
                        <Image
                            src="/images/illustrations/maszynypolskie_mobile_signing.png"
                            alt="CodeWorld Company"
                            className={classes.image}
                            data-aos="fade-up"
                        />
                    </Grid>
                </Grid>


                <Grid container spacing={isMd ? 4 : 2}>
                    <Grid justify item xs={12} sm={6}>
                        <Paper>
                            <Image
                                src="/images/illustrations/maszynypolskie_protocol.jpg"
                                alt="TheFront Company"
                                className={classes.image}
                                data-aos="fade-up"
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper>
                            <Image
                                src="/images/illustrations/maszynypolskie_protocol2.jpg"
                                alt="CodeWorld Company"
                                className={classes.image}
                                data-aos="fade-up"
                            />
                        </Paper>
                    </Grid>
                </Grid>

                <Paper style={{marginTop: 20}}>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        alignItems="center"
                        justify="center"

                    >
                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            alignItems="center"
                            justify="center"
                            // style={{ width: 550 }}
                        >
                            <Image
                                src="/images/illustrations/maszynypolskie_web.png"
                                alt="CodeWorld Company"
                                className={classes.image}
                                data-aos="fade-up"
                            />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper style={{marginTop: 20}}>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        alignItems="center"
                        justify="center"

                    >
                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            alignItems="center"
                            justify="center"
                            // style={{ width: 550 }}
                        >
                            <Image
                                src="/images/illustrations/maszynypolskie_web2.png"
                                alt="CodeWorld Company"
                                className={classes.image}
                                data-aos="fade-up"
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Section>

    );
};

export default MaszynyPolskie;