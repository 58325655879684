import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, IconButton} from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import PinterestIcon from '@material-ui/icons/Pinterest';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';

import {Image} from 'components/atoms';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3, 0),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(6, 0),
        },
        background: theme.palette.background.footer,
    },
    footerContainer: {
        maxWidth: theme.layout.contentWidth,
        width: '100%',
        margin: '0 auto',
        padding: theme.spacing(0, 2),
    },
    logoContainerItem: {
        paddingTop: 0,
    },
    logoContainer: {
        width: 130,
        // height: 28,
        [theme.breakpoints.up('md')]: {
            width: 130,
            // height: 32,
        },
    },
    listItemText: {
        flex: '0 0 auto',
        whiteSpace: 'nowrap',
        textDecoration: 'none',
    },
    logoImage: {
        width: '100%',
        height: '100%',
    },
    socialIcon: {
        padding: 0,
        marginRight: theme.spacing(1),
        color: 'rgba(255,255,255,.6)',
        '&:hover': {
            background: 'transparent',
        },
        '&:last-child': {
            marginRight: 0,
        },
    },
    icon: {
        fontSize: 24,
    },
    navigationContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    toolbar: {
        maxWidth: theme.layout.contentWidth,
        width: '100%',
        margin: '0 auto',
        padding: theme.spacing(0, 2),
    },
    listItem: {
        cursor: 'pointer',
        paddingTop: 0,
        paddingBottom: 0,
    },
    footerItem: {
        color: '#BFBFBF',
        textDecoration: 'none'
    }
}));

const Footer = props => {
    const {pages, className, ...rest} = props;

    const classes = useStyles();

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <div className={classes.footerContainer}>
                <Grid container spacing={3}>

                    <Grid item xs={6} sm={3}>
                        <div className={classes.logoContainer}>
                            <a href="/" title="thefront">
                                <Image
                                    className={classes.logoImage}
                                    src="/images/logos/codeworld_logo_grey.png"
                                    alt="thefront"
                                    lazy={false}
                                />
                            </a>
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <IconButton className={classes.socialIcon}>
                            <FacebookIcon className={classes.icon}/>
                        </IconButton>
                        <IconButton className={classes.socialIcon}>
                            <InstagramIcon className={classes.icon}/>
                        </IconButton>
                        <IconButton className={classes.socialIcon}>
                            <TwitterIcon className={classes.icon}/>
                        </IconButton>
                        <IconButton className={classes.socialIcon}>
                            <PinterestIcon className={classes.icon}/>
                        </IconButton>
                    </Grid>


                    <Grid item xs={6} sm={3} className={classes.footerItem}>
                        <a className={classes.footerItem} href="tel:+48507149692"><PhoneIcon style={{height: 12}}/>+48 507 149 692</a> <br/>
                        <EmailIcon style={{height: 12}}/><a className={classes.footerItem} href="mailto:biuro@codeworld.pl">biuro@codeworld.pl</a><br/>
                    </Grid>
                    <Grid item xs={6} sm={3} className={classes.footerItem}>
                        CODE WORLD<br/>
                        Paweł Frankowski<br/>
                        87-100 Toruń<br/>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

Footer.propTypes = {
    className: PropTypes.string,
    pages: PropTypes.object.isRequired,
};

export default Footer;
