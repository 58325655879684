import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useMediaQuery, Grid, Button, Typography} from '@material-ui/core';
import {Image} from 'components/atoms';
import {SectionHeader} from 'components/molecules';
import {Link} from "react-router-dom";
import {CardBase} from "../../../../components/organisms";

const useStyles = makeStyles(theme => ({
    root: {},
    image: {
        boxShadow:
            '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
        borderRadius: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            maxWidth: 500,
        },
    },
    lastGrid: {
        [theme.breakpoints.up('sm')]: {
            marginTop: '40%',
        },
    },
}));

const OurProjects = props => {
    const {className, ...rest} = props;
    const classes = useStyles();

    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    return (
        <div className={clsx(classes.root, className)} {...rest}>
            <SectionHeader
                title="Nasze projekty"
                subtitle=""
                align="center"
                ctaGroup={[
                    <Link
                        to='/projects'
                        style={{fontSize: 20, textDecoration: 'none'}}
                    >
                        <Typography component="span" variant="inherit" color="primary">
                            Zobacz nasze realizacje
                        </Typography>
                    </Link>,
                ]}
            />
            <Grid container spacing={isMd ? 4 : 2}>
                <Grid item xs={12} sm={6}>
                    <Grid container justif="center" alignItems="center">

                        <Image
                            src="/images/illustrations/weglokoks_bis_shop.jpg"
                            alt="TheFront Company"
                            className={classes.image}
                            data-aos="fade-up"
                            style={{cursor: 'pointer'}}
                            onClick={() => window.open("https://wegiel-torun.pl", "_blank")}
                        />

                    </Grid>
                    <Grid container justif="center" alignItems="center" style={{marginTop: 50}}>

                        <Image
                            src="/images/illustrations/service_helpdesk_www_mobile.jpg"
                            alt="TheFront Company"
                            className={classes.image}
                            data-aos="fade-up"
                            style={{cursor: 'pointer'}}
                            onClick={() => window.open("https://service-helpdesk.pl", "_blank")}
                        />

                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid
                        container
                        justif="center"
                        alignItems="center"
                        className={classes.lastGrid}
                    >
                        <Image
                            src="/images/illustrations/maszyny_polskie.jpg"
                            alt="TheFront Company"
                            className={classes.image}
                            data-aos="fade-up"
                            // style={{cursor: 'pointer'}}
                        />
                    </Grid>
                    <Grid
                        style={{marginTop: 50}}
                        container
                        justif="center"
                        alignItems="center"
                        className={classes.lastGrid}
                    >
                        <Image
                            src="/images/illustrations/ternova_site.png"
                            alt="TheFront Company"
                            className={classes.image}
                            data-aos="fade-up"
                            // style={{cursor: 'pointer'}}
                        />
                    </Grid>
                </Grid>





            </Grid>
        </div>
    );
};

OurProjects.propTypes = {
    /**
     * External classes
     */
    className: PropTypes.string,
};

export default OurProjects;
