export const integrations = [
  {
    logo: '/images/logos/java.png',
    name: 'Java',
  },
  {
    logo: '/images/logos/spring.png',
    name: 'Spring',
  },
  {
    logo: '/images/logos/react.png',
    name: 'React',
  },
  {
    logo: '/images/logos/android.png',
    name: 'Android',
  },
  {
    logo: '/images/logos/mysql.png',
    name: 'MySQL',
  },
  {
    logo: '/images/logos/postgresql.png',
    name: 'PostgreSQL',
  },
];
