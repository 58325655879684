import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {
    Toolbar,
    Hidden,
    List,
    ListItem,
    Typography,
    IconButton,
    Button, useScrollTrigger, Slide, AppBar,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';

import {Image} from 'components/atoms';
import {Link} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {},
    flexGrow: {
        flexGrow: 1,
    },
    navigationContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    toolbar: {
        maxWidth: theme.layout.contentWidth,
        width: '100%',
        margin: '0 auto',
        padding: theme.spacing(0, 2),
        backgroundColor: 'white',
        height: 50
    },
    listItem: {
        width: 130,
        cursor: 'pointer',
        paddingTop: 0,
        paddingBottom: 0,
    },
    listItemPhone: {
        width: 180,
        paddingTop: 0,
        paddingBottom: 0,
        marginLeft: 50
    },
    listItemText: {
        flex: '0 0 auto',
        whiteSpace: 'nowrap',
        textDecoration: 'none',
        color: 'grey'
    },
    listItemButton: {
        whiteSpace: 'nowrap',
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
    logoContainer: {
        width: 150,
        // height: 28,
        [theme.breakpoints.up('md')]: {
            width: 250,
            // height: 32,
        },
    },
    logoImage: {
        width: '100%',
        height: '100%',
    },
}));

const Topbar = props => {
    const {onSidebarOpen, pages, ...rest} = props;

    const classes = useStyles();

    function HideOnScroll(props) {
        const { children, window } = props;
        // Note that you normally won't need to set the window ref as useScrollTrigger
        // will default to window.
        // This is only being set here because the demo is in an iframe.
        const trigger = useScrollTrigger({ target: window ? window() : undefined });

        return (
            <Slide appear={false} direction="down" in={!trigger}>
                {children}
            </Slide>
        );
    }

    HideOnScroll.propTypes = {
        children: PropTypes.element.isRequired,
        /**
         * Injected by the documentation to work in an iframe.
         * You won't need it on your project.
         */
        window: PropTypes.func,
    };


    return (
        <HideOnScroll {...props}>
            <AppBar style={{backgroundColor: 'white'}}>
        <Toolbar disableGutters className={classes.toolbar} style={{height: 80}} {...rest}>
            <div
                className={classes.logoContainer}
            >
                <Link to="/" title="CodeWorld">
                    <Image
                        src="/images/logos/codeworld_logo.svg"
                        alt="thefront"
                        lazy={false}
                    />
                </Link>
            </div>
            <div className={classes.flexGrow}/>
            <Hidden smDown>

                <List className={classes.navigationContainer}>
                    <ListItem className={classes.listItem}>

                        <Link
                            variant="body1"
                            color="textSecondary"
                            className={classes.listItemText}
                            to="/"
                        >
                            <HomeIcon />
                        </Link>
                    </ListItem>

                    <ListItem className={classes.listItem}>
                        <Link
                            variant="body1"
                            color="textSecondary"
                            className={classes.listItemText}
                            to="/projects"
                        >
                            Nasze realizacje
                        </Link>
                    </ListItem>

                    <ListItem className={classes.listItemPhone}>
                        <Typography
                            variant="body1"
                            color="textSecondary"
                            className={classes.root}
                        >
                            <a className={classes.listItemText} href="tel:+48507149692">tel. +48 507 149 692</a>

                        </Typography>
                    </ListItem>

                </List>
            </Hidden>
            <Hidden mdUp>
                <IconButton
                    className={classes.iconButton}
                    onClick={onSidebarOpen}
                    aria-label="Menu"
                >
                    <MenuIcon/>
                </IconButton>
            </Hidden>
        </Toolbar>
            </AppBar>
        </HideOnScroll>
    );
};

Topbar.propTypes = {
    onSidebarOpen: PropTypes.func,
    pages: PropTypes.object.isRequired,
};

export default Topbar;
