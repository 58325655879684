import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useMediaQuery, Grid, Button, TextField} from '@material-ui/core';
import {SectionHeader} from 'components/molecules';
import {AppContext} from "../../../../AppContext";
import axios from "axios";
import {Alert} from "@material-ui/lab";


const useStyles = makeStyles(theme => ({
    root: {},
    fontWeight900: {
        fontWeight: 900,
    },
}));

const Contact = props => {
    const {className, ...rest} = props;
    const classes = useStyles();

    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const context = useContext(AppContext);

    const handleOnChangeEmailMessage = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        context.toggleHomeState({
                ...context.home,
                [name]: value
            }
        )
    }

    const sendEmail = () => {

        let validation = true;
        let errorEmail = false;
        let errorEmailMessage = ''
        let errorMessage = false;
        let errorMessageMessage = ''
        let email = context.home.senderEmail.trim();
        if (!validateEmail(email)) {
            errorEmail = true;
            errorEmailMessage = 'Niepoprawny adres email';
            validation = false;
        }

        if (context.home.emailMessage.length < 3) {
            errorMessage = true;
            errorMessageMessage = 'Wpisz treść wiadomości'
            validation = false;
        }

        if (validation) {
            const res = axios.post(`https://codeworld.pl:8445/api/email/send`, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json"
                },
                sendersEmail: email,
                subject: 'Wiadomość z formularza kontaktowego codeworld.pl',
                message: context.home.emailMessage
            }).then(res => {
                if (res.status === 200) {
                    console.log("Wysłano email")
                    context.toggleHomeState({
                        ...context.home,
                        errorEmail: false,
                        errorEmailMessage: '',
                        errorMessage: false,
                        errorMessageMessage: '',
                        senderEmail: '',
                        emailMessage: '',
                        successSendEmail: true,
                    })
                }
            })
                .catch((error) => {
                    console.log({error})
                    context.toggleHomeState({
                        ...context.home,
                        errorEmail: false,
                        errorEmailMessage: '',
                        errorMessage: false,
                        errorMessageMessage: '',
                        successSendEmail: false,
                    })
                });
        } else {
            context.toggleHomeState({
                ...context.home,
                errorEmail: errorEmail,
                errorEmailMessage: errorEmailMessage,
                errorMessage: errorMessage,
                errorMessageMessage: errorMessageMessage,
                successSendEmail: '',
            })
        }


    }

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }


    return (


        <div className={clsx(classes.root, className)} {...rest} >
            <SectionHeader
                style={{fontColor: '#27a9d6'}}
                title="Napisz do nas"
                subtitle="Formularz kontaktowy"

                data-aos="fade-up"
            />
            <TextField
                id="outlined-multiline-static"
                label="Twój email"
                multiline
                onChange={handleOnChangeEmailMessage}
                name='senderEmail'
                placeholder="wpisz email..."
                variant="outlined"
                fullWidth
                value={context.home.senderEmail}
                style={{marginBottom: 20}}
                error={context.home.errorEmail}
                helperText={context.home.errorEmailMessage}
            />

            <TextField
                id="outlined-multiline-static"
                label="Wiadomość"
                multiline
                rows={5}
                value={context.home.emailMessage}
                onChange={handleOnChangeEmailMessage}
                name='emailMessage'
                placeholder="napisz wiadomość..."
                variant="outlined"
                fullWidth
                error={context.home.errorMessage}
                helperText={context.home.errorMessageMessage}
            />

            <Grid container justify="flex-end">
                {context.home.successSendEmail ? <Alert style={{margin: 4}} severity="success">Wysłano pomyślnie</Alert> : false}
                {context.home.successSendEmail === false ? <Alert severity="error">Wiadomość nie została wysłana</Alert> : false}
                <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.margin}
                    onClick={sendEmail}
                    style={{marginTop: 4, marginBottom: 4}}>
                    Wyślij
                </Button>

            </Grid>

        </div>
    );
};

Contact.propTypes = {
    /**
     * External classes
     */
    className: PropTypes.string,
};

export default Contact;
